import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import { Alert, DatePicker, message, PageHeader, Spin } from 'antd';
import { useState } from 'react';
import PriceAnalysisGrid from './grid';
import getErrorMessage from '@/utils/getError';
import { get_outlet_pricing_by_date } from '@/services/products/queries';
import { IOutletPricingByDate } from '@/services/products/types';

function PriceAnalysisCreate() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>();
  const [currentPriceAnalysis, setCurrentPriceAnalysis] = useState<IOutletPricingByDate>();

  async function handleDateChange(date: moment.Moment | null) {
    try {
      const dateString = date ? date.format('YYYY-MM-DD') : '';
      setSelectedDate(dateString);

      if (date) {
        const prevDate = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
        const todayPriceAnalysis = await get_outlet_pricing_by_date(dateString);

        if (todayPriceAnalysis) {
          message.error('Price analysis has already been created for selected date.');
          setSelectedDate(undefined);
          return;
        }

        const yesterdayPriceAnalysis = await get_outlet_pricing_by_date(prevDate);
        setCurrentPriceAnalysis(yesterdayPriceAnalysis);
      }
    } catch (error) {
      setSelectedDate(undefined);
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={[{ label: 'Price Analysis', link: '/price-analysis' }, { label: 'New' }]}>
        <Alert
          message="Important Information"
          description="Once a date is selected, it cannot be changed. Please ensure you select the correct date initially."
          type="info"
          showIcon
          closable
        />

        <div className="mt-2 space-x-2">
          <label htmlFor="date-picker">Select Date:</label>
          <DatePicker
            id="date-picker"
            format={'YYYY-MM-DD'}
            showTime
            allowClear={false}
            disabled={!!selectedDate}
            value={selectedDate ? moment(selectedDate) : undefined}
            disabledDate={(current) => current && current > moment().endOf('day')}
            onChange={(date) => handleDateChange(date)}
          />
        </div>

        <PageHeader subTitle="Price Analysis" style={{ padding: '8px 0' }} />
        <PriceAnalysisGrid
          date={selectedDate}
          setIsLoading={setIsLoading}
          priceAnalysis={currentPriceAnalysis}
        />
      </AppContent>
    </Spin>
  );
}

export default PriceAnalysisCreate;
