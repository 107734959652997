import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  IClearStockDetails,
  ICreatePriceGroup,
  IExpiredProductResponse,
  ILotDetails,
  ILotsTotalWithLocationId,
  IOutletPricingByDate,
  IPriceAnalysis,
  IPriceAnalysisDetails,
  IPriceAnalysisResponse,
  IPriceTypeById,
  IProductDetails,
  IProductListResponse,
  IProductPreviousLots,
  IProductPriceGroupResponse,
  IProductsLinesAvgQty,
  IProductsLinesSumQty,
  IProductsLotsSum,
  IProductSoldTotal,
  IProductStatusResponse,
  IStockClear,
  IUnits
} from './types';
import { ILot } from '../purchases/types';
import {
  getDataForIds,
  getDataForIdsByDates,
  getDataForIdsByDynamicField,
  getDataOnlyForIds,
  IAllResponse
} from '@/utils/queries.utils';
import { LineType } from '../report/enums';

/* Get Products lists
 * @param {number} skip
 * @param {number} count
 * @params {value} string
 * @returns {Promise<any>}
 */
export const get_product_list = async (skip = 0, count = 0, value = '', locationId?: number) => {
  const response: AxiosResponse<IProductListResponse, any> = await http.get('products', {
    skip,
    count,
    value,
    locationId
  });
  return response;
};

export const get_product_list_only = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IProductListResponse, any> = await http.get('products/?' + filter, {
    skip,
    count,
    value
  });
  return response.data.results;
};

export const get_product_list_categoryId = async (
  skip = 0,
  count = 0,
  value = '',
  categoryId?: number,
  locationId?: number
) => {
  const response: AxiosResponse<IProductListResponse, any> = await http.get('products', {
    skip,
    count,
    value,
    categoryId,
    locationId
  });
  return response;
};

export const get_product_list_ids = async (ids: string[] | number[]) => {
  const parsedIds = ids.map(Number);
  const response = await getDataForIds<IProductDetails>(parsedIds, 'products/?');
  return { data: response } as AxiosResponse<IProductListResponse, any>;
};

export const get_product_list_filter = async (url: string, skip = 0, count = 0) => {
  const response: AxiosResponse<IProductListResponse, any> = await http.get('products/?' + url, {
    skip,
    count
  });
  return response;
};
export const get_product_list_v2 = async (url: string) => {
  const response: AxiosResponse<IProductListResponse, any> = await http.get('products/?' + url);
  return response;
};

export const get_expired_products_list = async (url: string) => {
  const response: AxiosResponse<IExpiredProductResponse> = await http.get(
    'products/expiry/?' + url
  );
  return response.data;
};

/* Get units lists
 */
export const get_units_list = async () => {
  const response: AxiosResponse<IUnits[], any> = await http.get('units');
  return response.data;
};

/* Get single product details
 * @param {number} id
 */
export const get_product_details = async (id: number) => {
  const response: AxiosResponse<IProductDetails, any> = await http.get('products/' + id);
  return response.data;
};

export const get_lots_details = async (id: number) => {
  const response: AxiosResponse<ILotDetails[], any> = await http.get('products/lots/' + id);
  return response.data;
};

export const get_product_lots_details = async (id: number) => {
  const response: AxiosResponse<ILotDetails, any> = await http.store('products/lots/' + id);
  return response.data;
};

export const get_lots_details_bylocationId_productId = async (
  locationId: number,
  productId: number
) => {
  const response: AxiosResponse<ILotDetails[], any> = await http.get(
    'products/locations/' + locationId + '/lots/' + productId
  );
  return response.data;
};

export const get_unexpired_lots_details_bylocationId_productId = async (
  locationId: number,
  productId: number
) => {
  const response: AxiosResponse<ILotDetails[], any> = await http.get(
    'products/locations/' + locationId + '/without-expired-lots/' + productId
  );
  return response.data;
};

export const get_unexpired_lots_details_bylocationId_productIds = async (
  locationId: number,
  productIds: number[]
) => {
  return await getDataOnlyForIds<ILotDetails>(productIds, 'products/without-expired-lots/?', {
    locationId
  });
};

export const get_lots_details_bylocationId_multipleProductIds = async (
  ids: number[] | string[],
  locationId: number,
  skip = 0,
  count = 100
) => {
  const parsedIds = ids.map(Number);
  return getDataOnlyForIds<ILotDetails>(parsedIds, '/products/locations/lots-multiple?', {
    locationId,
    skip,
    count
  });
};
/* Delete product
 ** @@ params: id
 */
export const delete_product = async (id: number) => {
  const response = await http.remove('products/' + id);
  return response;
};
export const get_price_groups_by_location = async (locationId: any) => {
  // console.log('location Idd', locationId);
  const response: AxiosResponse<ICreatePriceGroup[], any> = await http.get(
    'prices/price-groups/' + locationId
  );
  return response;
};

export const get_price_by_group = async (id: number | string) => {
  const response: AxiosResponse<IPriceTypeById[], any> = await http.get('prices/prices/' + id);
  return response;
};
export const prices_download_by_pricegroupid = async (pricegroupId: number) => {
  const response = await http.get('prices/download/' + pricegroupId);
  return response;
};

export const prices_by_groupId = async (pricegroupId: number) => {
  const response: AxiosResponse<IPriceTypeById[], any> = await http.get(
    'prices/prices/' + pricegroupId
  );
  return response;
};

export const get_lots_by_location = async (locationId: number) => {
  const response: AxiosResponse<ILot[], any> = await http.get(
    `products/locations/${locationId}/lots`
  );
  return response;
};

export const get_total_lots_with_location = async (locationId: number) => {
  const response: AxiosResponse<ILotsTotalWithLocationId, any> = await http.get(
    'products/lots-total/' + locationId
  );
  return response;
};

export const get_total_sold_products = async (filter = '') => {
  const response: AxiosResponse<{ results: IProductSoldTotal[] }, any> = await http.get(
    'products/sold-total/?' + filter
  );
  return response;
};

export const get_total_purchase_products = async (filter = '') => {
  const response: AxiosResponse<{ results: IProductSoldTotal[] }, any> = await http.get(
    'products/purchase-total/?' + filter
  );
  return response;
};
// export const get_products_list_ids = async (ids: string[] | number[]) => {
//   const response = await http.get('products/', { ids, count: ids.length });
//   return response;
// };

export const get_product_status_list = async (filter = '') => {
  const response: AxiosResponse<IProductStatusResponse, any> = await http.get(
    'products-status/?' + filter
  );
  return response.data;
};

export const get_product_pricegroup_list = async (filter = '') => {
  const response: AxiosResponse<IProductPriceGroupResponse[], any> = await http.get(
    'prices/prices/?' + filter
  );
  return response.data;
};

export const get_previous_lots_list_filter = async (filter = '', ids: number[]) => {
  const data = await getDataForIdsByDynamicField<IProductPreviousLots>(
    ids,
    `products/lots-previous/?${filter}`,
    { totalField: 'count' }
  );

  return { count: data.total, results: data.results };
};

export const get_product_zero_lots_filter = async (filter = '') => {
  const response: AxiosResponse<IAllResponse<IStockClear>> = await http.get('lot-0/?' + filter);
  return response.data;
};

export const get_zero_lots_details = async (id: number) => {
  const response: AxiosResponse<IClearStockDetails> = await http.get('lot-0/' + id);
  return response.data;
};

export const get_outlet_pricing_by_date = async (date: string) => {
  const response: AxiosResponse<IOutletPricingByDate> = await http.get(
    'price-analysis/outlet-pricing-by-date',
    { date }
  );

  if (Array.isArray(response.data) && response.data.length === 0) {
    return undefined;
  }

  return response.data;
};

export const get_products_lines_avg_qty = async (
  productIds: number[],
  dates: { startDate: string; endDate: string },
  identifiers?: LineType[]
) => {
  return await getDataOnlyForIds<IProductsLinesAvgQty>(productIds, 'products/lines-avg-qty?', {
    ...dates,
    identifiers
  });
};

export const get_products_lots_sum = async (
  productIds: number[],
  dates: { startDate: string; endDate: string }
) => {
  return await getDataOnlyForIds<IProductsLotsSum>(productIds, 'products/lots-sum?', dates);
};

export const get_products_lines_sum_qty = async (
  productIds: number[],
  dates: { startDate: string; endDate: string },
  identifiers?: LineType[]
) => {
  return await getDataOnlyForIds<IProductsLinesSumQty>(productIds, 'products/lines-sum-qty?', {
    ...dates,
    identifiers
  });
};

export const get_price_analysis_list = async (filter = '') => {
  const response: AxiosResponse<IPriceAnalysisResponse> = await http.get(
    'price-analysis/?' + filter
  );
  return response.data;
};

export const get_price_analysis_id = async (id: number) => {
  const response: AxiosResponse<IPriceAnalysisDetails> = await http.get('price-analysis/' + id);
  return response.data;
};
